import { AbstractMesh, Animation, ArcRotateCamera, BlackAndWhitePostProcess, Color4, Engine, EngineOptions, HemisphericLight, IAnimationKey, IParticleSystem, MeshBuilder, PassPostProcess, PointLight, PostProcessOptions, Scene, SceneComponentConstants, SceneLoader, Skeleton, Vector3 } from 'babylonjs';
import * as Materials from 'babylonjs-materials';

async function createScene(canvas: HTMLCanvasElement, engine: Engine): Promise<Scene> {

    // Create the scene space
    const scene = new Scene(engine);
    scene.clearColor = new Color4(0, 0, 0, 0);
    //scene.autoClear = false;

    // Add a camera to the scene and attach it to the canvas
    const camera = new ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 2, new Vector3(0,0,5), scene);
    camera.attachControl(canvas, true);

    // Add lights to the scene
    const light1 = new HemisphericLight("light1", new Vector3(1, 1, 0), scene);
    const light2 = new PointLight("light2", new Vector3(0, 1, -1), scene);

    // Add and manipulate meshes in the scene
    //const sphere = MeshBuilder.CreateSphere("sphere", {diameter:2}, scene);

    // const skyMaterial = new Materials.SkyMaterial('sky', scene);
    //sphere.setMa

    const mesh: AbstractMesh = await new Promise(resolve => {
        SceneLoader.ImportMesh("", "scenes/", "skull.babylon", scene, function(meshes: AbstractMesh[], particleSystems: IParticleSystem[], skeletons: Skeleton[], animationGroups: AnimationGroup[]) {
            const obj = meshes[0];
            const n = 180;
            obj.rotation.y = n * Math.PI / 180;//90 * 2 * Math.PI;
            obj.scaling.setAll(0.25);
            obj.position.x = -2;
            obj.position.y = 2;
            obj.position.z = -30;
            //scene.addMesh(meshes[0]);
            resolve(obj);
        });
    });
    //scene.createDefaultCameraOrLight(true, true, true);
    //scene.createDefaultEnvironment();
    //scene.debugLayer.show();

    const anim = new Animation("myAnimation", "rotation.y", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);

    const keys: IAnimationKey[] = [
        {frame: 0, value: 0 * Math.PI / 180 },
        {frame: 240, value: 180 * Math.PI / 180},
        {frame: 480, value: 360 * Math.PI / 180},
    ];
    anim.setKeys(keys);

    mesh.animations.push(anim);
    scene.beginAnimation(mesh, 0, 480, true);

    const postProcessPass = new PassPostProcess("pass", 0.15, camera);
    //const postProcessBw = new BlackAndWhitePostProcess("bandw", 0.1, camera);

    return scene;
};

/*
// better positioned(?) skull

var createScene = function () {
    var scene = new BABYLON.Scene(engine);

    //Adding a light
    var light = new BABYLON.PointLight("Omni", new BABYLON.Vector3(20, 20, 100), scene);

    //Adding an Arc Rotate Camera
    var camera = new BABYLON.ArcRotateCamera("Camera", 0, 0.8, 100, BABYLON.Vector3.Zero(), scene);
    camera.attachControl(canvas, false);

    // The first parameter can be used to specify which mesh to import. Here we import all meshes
    BABYLON.SceneLoader.ImportMesh("", "scenes/", "skull.babylon", scene, function (newMeshes) {
        // Set the target of the camera to the first imported mesh
        camera.target = newMeshes[0];
    });

    // Move the light with the camera
    scene.registerBeforeRender(function () {
        light.position = camera.position;
    });

    return scene;
}
*/

export async function start3d() {
    let canvas: HTMLCanvasElement = document.getElementById("renderCanvas") as HTMLCanvasElement; // Get the canvas element
    let antialias: boolean = true;
    let options: EngineOptions = { }; //alpha: true };
    let adaptToDeviceRatio: boolean = true;
    let engine: Engine = new Engine(canvas, antialias, options, adaptToDeviceRatio); // Generate the BABYLON 3D engine
    let scene: Scene = await createScene(canvas, engine); //Call the createScene function

    // Register a render loop to repeatedly render the scene
    engine.runRenderLoop(function () {
        scene.render();
    });

    // Watch for browser/canvas resize events
    window.addEventListener("resize", function () {
        engine.resize();
    });
}
