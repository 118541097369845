// Test non-dynamic loading
import * as threedee from './3d';
threedee.start3d()

// Test dynamic loading of module
// if (Math.random() > 0.5) {
//     import('./3d').then(function(threedee) {
//         threedee.start3d()
//     })
// }

let compoData = {

};

class Template {
    private tagCompoName: string = '$$COMPONAME$$';
    private tagEntryNumber: string = '$$ENTRY_NUMBER$$';
    private tagEntryName: string = '$$ENTRY_NAME$$';
    private tagEntryAuthor: string = '$$ENTRY_AUTHOR$$';
    private tagEntryComment: string = '$$ENTRY_COMMENT';

    private compoSlideStart: string;
    private compoSlideEntry: string;
    private compoSlideEnd: string;

    constructor() {
        this.compoSlideStart = `
<div>
    <div>The ${this.tagCompoName} compo</div>
    <div>will start</div>
    <div>now!</div>
<div>
`;

        this.compoSlideEntry = `
<div>
    <div>${this.tagCompoName}</div>
    <div>${this.tagEntryNumber} ${this.tagEntryName}</div>
    <div>${this.tagEntryAuthor}</div>
    <div>${this.tagEntryComment}</div>
</div>
`;

        this.compoSlideEnd = `
<div>
    <div>The ${this.tagCompoName} compo</div>
    <div>is</div>
    <div>over!</div>
</div>
`;

    }
}


